import { galleryPageContent } from '../utils/dataConfig';

const initialState = {
  visitor: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    buildUrl: null,
    quantity: 1
  },
  submitted: [],
  gallery: {
    currentTab: galleryPageContent.tabs[0].name,
    imageModal: null,
    videoModal: null,
  },
};

export const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORM_SUBMITTED: {
      const [formName, formValues] = payload;
      const { build, ...visitorInfo } = formValues;
      return {
        ...state,
        visitor: { ...state.visitor, ...visitorInfo },
        submitted: [...state.submitted, formName],
      };
    }
    case FORM_SUBMISSION_RESET:
      return {
        ...state,
        visitor: { ...state.visitor, buildUrl: null },
        submitted: state.submitted.filter(formName => formName !== payload),
      };
    case GALLERY_IMAGE_CHANGED:
      return {
        ...state,
        gallery: { ...state.gallery, imageModal: payload },
      };
    case GALLERY_VIDEO_CHANGED:
      return {
        ...state,
        gallery: { ...state.gallery, videoModal: payload },
      };
    case GALLERY_TAB_CHANGED:
      return {
        ...state,
        gallery: { ...state.gallery, currentTab: payload },
      };
    default:
      return state;
  }
};

// selectors
export const getVisitorInfo = state => state.app.visitor;
export const getSubmittedForms = state => state.app.submitted;
export const getGalleryImageModal = state => state.app.gallery.imageModal;
export const getGalleryVideoModal = state => state.app.gallery.videoModal;
export const getGalleryTab = state => state.app.gallery.currentTab;

// action types
export const FORM_SUBMITTED = 'app/formSubmitted';
export const GALLERY_IMAGE_CHANGED = 'app/galleryImageChanged';
export const GALLERY_VIDEO_CHANGED = 'app/galleryVideoChanged';
export const GALLERY_TAB_CHANGED = 'app/galleryTabChanged';
export const FORM_SUBMISSION_RESET = 'app/formSubmissionReset';
// action creators
export const saveFormSubmission = (formName, formValues) => ({
  type: FORM_SUBMITTED,
  payload: [formName, formValues],
});
export const changeGalleryImageModal = (modalOpen, imageName = null) => ({
  type: GALLERY_IMAGE_CHANGED,
  payload: modalOpen && imageName,
});
export const changeGalleryVideoModal = modal => ({
  type: GALLERY_VIDEO_CHANGED,
  payload: modal,
});
export const changeGalleryTab = tab => ({
  type: GALLERY_TAB_CHANGED,
  payload: tab,
});
export const resetFormSubmission = formName => ({
  type: FORM_SUBMISSION_RESET,
  payload: formName,
});
