import { formatPrice } from './helpers';

const exteriorData = {
  wood: {
    title: 'Wood',
  },
  metal: {
    title: 'Metal',
  },
  solid: {
    title: 'Solid',
  },
  cyber: {
    title: 'Neu Garage',
  },
  colors: {
    'white-red': { title: 'Red' },
    copper: { title: 'Copper' },
    silver: { title: 'Silver' },
    black: { title: 'Black' },
    'black-dark': { title: 'Black trim dark' },
    'white-espresso': { title: 'White trim espresso' },
    'white-gray': { title: 'White trim gray' },
    'white-green': { title: 'White trim green' },
    'white-light-brown': { title: 'White trim light brown' },
    'black-brown': { title: 'Black trim brown' },
    'black-gray': { title: 'Black trim gray' },
    'black-silver': { title: 'Black trim silver' },
    'white-blue': { title: 'White trim blue' },
    'white-brown': { title: 'White trim brown' },
  },
};
const getExteriorOptionSubtitle = config => {
  const prices = [];
  config.list.forEach(option => prices.push(config[option]));
  const minPrice = Math.min(...prices);
  return `starting at ${formatPrice(minPrice)} / mon`;
};
const getExteriorData = pricing => {
  const configWithPricing = { list: pricing.list };
  pricing.list.forEach(option => {
    configWithPricing[option] = {
      ...exteriorData[option],
      subtitle: getExteriorOptionSubtitle(pricing[option].colors),
    };
    configWithPricing[option].colors = {
      list: pricing[option].colors.list,
    };
    pricing[option].colors.list.forEach(color => {
      configWithPricing[option].colors[color] = {
        ...exteriorData.colors[color],
        ...pricing[option].colors[color],
      };
    });
  });
  return configWithPricing;
};

const roofData = {
  standard: {
    title: 'Standard flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  garden: {
    title: 'Garden',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  'garden-skylight': {
    title: 'Garden + Sky light',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  skylight: {
    title: 'Sky light',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  porch: {
    title: 'Front Porch Portico',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  canopy: {
    title: 'Canopy',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  'roof-deck': {
    title: '2nd Floor Roof Deck',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  'roof-deck-garden': {
    title: '2F Roof Deck + Garden',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
  'porch-garden': {
    title: 'Front Porch + Garden',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
  },
};
const getRoofData = pricing => {
  const configWithPricing = { list: pricing.list };
  pricing.list.forEach(option => {
    configWithPricing[option] = {
      ...roofData[option],
      price: pricing[option].price,
      fullPrice: pricing[option].fullPrice,
    };
  });
  return configWithPricing;
};

const interiorData = {
  light: {
    title: 'Light',
  },
  dark: {
    title: 'Dark',
  },
  concrete: {
    title: 'Concrete',
  },
  cyber: {
    title: 'Neu Garage',
  },
};
const getInteriorData = pricing => {
  const configWithPricing = {
    list: pricing.list,
    includes: pricing.includes,
    addOnIncludes: pricing.addOnIncludes,
  };
  pricing.list.forEach(option => {
    configWithPricing[option] = {
      ...interiorData[option],
      price: pricing[option].price,
      fullPrice: pricing[option].fullPrice,
    };
    configWithPricing[option].addOn = {
      price: pricing[option].addOnPrice,
      fullPrice: pricing[option].addOnFullPrice,
    };
  });
  return configWithPricing;
};

const bathroomColorsData = {
  gray: {
    title: 'Gray',
  },
  dark: {
    title: 'Dark',
  },
  blue: {
    title: 'Blue',
  },
  green: {
    title: 'Green',
  },
  white: {
    title: 'White',
  },
  light: {
    title: 'Light',
  },
};
const getBathroomColorData = pricing => {
  const configWithPricing = { list: pricing.list };
  pricing.list.forEach(option => {
    configWithPricing[option] = {
      ...bathroomColorsData[option],
      price: pricing[option].price,
      fullPrice: pricing[option].fullPrice,
    };
  });
  return configWithPricing;
};
const sampleAppliances = [
  'Refrigerator, Dishwasher, Oven, Stovetop, Microwave',
  // 'Dishwasher',
  // 'Oven',
  // 'Stovetop',
  // 'Microwave',
];
const sampleFurniture = [
  'Couches',
  'Beds',
  'Armchairs',
  'Nightstands',
  'Dining table',
  'Dresser',
  'Dining chairs',
  'Framed art',
  'Rugs',
];
export const unitDataConfig = {
  title: 'Select and build unit',
  subtitle: `1. Start by selecting a base model
2. Click "Customize" to modify base model
3. Drag and drop modules onto the grid `,
  note: '* Your configured price will be displayed below',
  options: {
    list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a', 'm16a', 'cyber'],
    m4a: {
      title: 'Starter Studio',
      description:
        'The basic building blocks of all Neu homes, the starter studio comes with your initial  four modules.',
      features: ['starting at 320 sq ft'],
      price: 495,
      fullPrice: 110_000,
      exterior: getExteriorData({
        list: ['solid', 'wood', 'metal'],
        solid: {
          colors: {
            list: [
              'white-gray',
              'white-espresso',
              'white-green',
              'white-light-brown',
              'black-dark',
            ],
            'white-light-brown': { price: 0, fullPrice: 0 },
            'white-gray': { price: 0, fullPrice: 0 },
            'white-espresso': { price: 0, fullPrice: 0 },
            'white-green': { price: 0, fullPrice: 0 },
            'black-dark': { price: 20, fullPrice: 4_500 },
          },
        },
        wood: {
          colors: {
            list: [
              'white-gray',
              'white-brown',
              'white-blue',
              'black-brown',
              'black-gray',
            ],
            'white-gray': { price: 40, fullPrice: 9_000 },
            'white-brown': { price: 40, fullPrice: 9_000 },
            'white-blue': { price: 60, fullPrice: 13_500 },
            'black-brown': { price: 60, fullPrice: 13_500 },
            'black-gray': { price: 60, fullPrice: 13_500 },
          },
        },
        metal: {
          colors: {
            list: ['black', 'white-red', 'silver', 'copper'],
            black: { price: 110, fullPrice: 24_500 },
            'white-red': { price: 110, fullPrice: 24_500 },
            silver: { price: 110, fullPrice: 24_500 },
            copper: { price: 120, fullPrice: 28_000 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
        concrete: {
          price: 40,
          fullPrice: 9_000,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
        dark: {
          price: 60,
          fullPrice: 13_500,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
      }),
      roof: getRoofData({
        list: ['standard', 'garden', 'skylight', 'garden-skylight'],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 60,
          fullPrice: 13_500,
        },
        skylight: {
          price: 90,
          fullPrice: 20_000,
        },
        'garden-skylight': {
          price: 150,
          fullPrice: 33_500,
        },
      }),
      bathroom: {
        list: ['master'],
        master: { title: 'Master' },
      },
      floorPlan: [
        [
          { group: 'bathroom', name: 'standard' },
          { group: 'dinning', name: 'standard-1' },
          { group: 'kitchen', name: '1' },
          { group: 'living', name: 'lr-1' },
        ],
      ],
    },
    m4b: {
      title: 'Studio L',
      description:
        'The starter with a twist. This “L” shaped studio provides more space for outdoor entry seating.',
      features: ['starting at 320 sq ft'],
      price: 545,
      fullPrice: 121_000,
      exterior: getExteriorData({
        list: ['solid', 'wood', 'metal'],
        solid: {
          colors: {
            list: [
              'white-gray',
              'white-espresso',
              'white-green',
              'white-light-brown',
              'black-dark',
            ],
            'white-light-brown': { price: 0, fullPrice: 0 },
            'white-gray': { price: 0, fullPrice: 0 },
            'white-espresso': { price: 0, fullPrice: 0 },
            'white-green': { price: 0, fullPrice: 0 },
            'black-dark': { price: 20, fullPrice: 4_500 },
          },
        },
        wood: {
          colors: {
            list: [
              'white-gray',
              'white-brown',
              'white-blue',
              'black-brown',
              'black-gray',
            ],
            'white-gray': { price: 40, fullPrice: 9_000 },
            'white-brown': { price: 40, fullPrice: 9_000 },
            'white-blue': { price: 60, fullPrice: 13_500 },
            'black-brown': { price: 60, fullPrice: 13_500 },
            'black-gray': { price: 60, fullPrice: 13_500 },
          },
        },
        metal: {
          colors: {
            list: ['black', 'white-red', 'silver', 'copper'],
            black: { price: 110, fullPrice: 24_500 },
            'white-red': { price: 110, fullPrice: 24_500 },
            silver: { price: 110, fullPrice: 24_500 },
            copper: { price: 120, fullPrice: 28_000 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
        concrete: {
          price: 40,
          fullPrice: 9_000,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
        dark: {
          price: 60,
          fullPrice: 13_500,
          addOnPrice: 100,
          addOnFullPrice: 22_300,
        },
      }),
      roof: getRoofData({
        list: ['standard', 'garden', 'skylight', 'garden-skylight'],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 60,
          fullPrice: 13_500,
        },
        skylight: {
          price: 90,
          fullPrice: 20_000,
        },
        'garden-skylight': {
          price: 150,
          fullPrice: 33_500,
        },
      }),
      bathroom: {
        list: ['master'],
        master: { title: 'Master' },
      },
      floorPlan: [
        [
          { group: 'bathroom', name: 'standard' },
          { group: 'dinning', name: 'standard-1' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'kitchen', name: '1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { vacant: true },
          { group: 'living', name: 'lr-1' },
        ],
      ],
    },
    m6a: {
      title: 'Studio Plus',
      description:
        'Stretch your legs out with the Studio Plus by adding an additional two modules to create a larger living area.',
      features: ['starting at 480 sq ft'],
      price: 645,
      fullPrice: 143_500,
      exterior: getExteriorData({
        list: ['solid', 'wood', 'metal'],
        solid: {
          colors: {
            list: [
              'white-gray',
              'white-espresso',
              'white-green',
              'white-light-brown',
              'black-dark',
            ],
            'white-light-brown': { price: 0, fullPrice: 0 },
            'white-gray': { price: 0, fullPrice: 0 },
            'white-espresso': { price: 0, fullPrice: 0 },
            'white-green': { price: 0, fullPrice: 0 },
            'black-dark': { price: 30, fullPrice: 6_500 },
          },
        },
        wood: {
          colors: {
            list: [
              'white-gray',
              'white-brown',
              'white-blue',
              'black-brown',
              'black-gray',
            ],
            'white-gray': { price: 60, fullPrice: 13_500 },
            'white-brown': { price: 60, fullPrice: 13_500 },
            'white-blue': { price: 90, fullPrice: 20_000 },
            'black-brown': { price: 90, fullPrice: 20_000 },
            'black-gray': { price: 90, fullPrice: 20_000 },
          },
        },
        metal: {
          colors: {
            list: ['black', 'white-red', 'silver', 'copper'],
            black: { price: 165, fullPrice: 35_500 },
            'white-red': { price: 165, fullPrice: 35_500 },
            silver: { price: 165, fullPrice: 35_500 },
            copper: { price: 180, fullPrice: 40_000 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
        concrete: {
          price: 60,
          fullPrice: 13_500,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
        dark: {
          price: 80,
          fullPrice: 18_000,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
      }),
      roof: getRoofData({
        list: ['standard', 'garden', 'skylight', 'garden-skylight'],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 80,
          fullPrice: 18_000,
        },
        skylight: {
          price: 90,
          fullPrice: 20_000,
        },
        'garden-skylight': {
          price: 170,
          fullPrice: 38_000,
        },
      }),
      bathroom: {
        list: ['master'],
        master: { title: 'Master' },
      },
      floorPlan: [
        [
          { group: 'bathroom', name: 'standard' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'kitchen', name: '1' },
          { group: 'dinning', name: 'standard-1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { group: 'living', name: 'lr-1' },
          { group: 'living', name: 'lr-2' },
        ],
      ],
    },
    m6b: {
      title: '1 Bedroom',
      description:
        'Need some privacy while you sleep. This six module configuration comes with your private bedroom.',
      features: ['starting at 480 sq ft'],
      price: 695,
      fullPrice: 154_500,
      exterior: getExteriorData({
        list: ['solid', 'wood', 'metal'],
        solid: {
          colors: {
            list: [
              'white-gray',
              'white-espresso',
              'white-green',
              'white-light-brown',
              'black-dark',
            ],
            'white-light-brown': { price: 0, fullPrice: 0 },
            'white-gray': { price: 0, fullPrice: 0 },
            'white-espresso': { price: 0, fullPrice: 0 },
            'white-green': { price: 0, fullPrice: 0 },
            'black-dark': { price: 30, fullPrice: 6_500 },
          },
        },
        wood: {
          colors: {
            list: [
              'white-gray',
              'white-brown',
              'white-blue',
              'black-brown',
              'black-gray',
            ],
            'white-gray': { price: 60, fullPrice: 13_500 },
            'white-brown': { price: 60, fullPrice: 13_500 },
            'white-blue': { price: 90, fullPrice: 20_000 },
            'black-brown': { price: 90, fullPrice: 20_000 },
            'black-gray': { price: 90, fullPrice: 20_000 },
          },
        },
        metal: {
          colors: {
            list: ['black', 'white-red', 'silver', 'copper'],
            black: { price: 165, fullPrice: 35_500 },
            'white-red': { price: 165, fullPrice: 35_500 },
            silver: { price: 165, fullPrice: 35_500 },
            copper: { price: 180, fullPrice: 40_000 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
        concrete: {
          price: 60,
          fullPrice: 13_500,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
        dark: {
          price: 80,
          fullPrice: 18_000,
          addOnPrice: 140,
          addOnFullPrice: 29_000,
        },
      }),
      roof: getRoofData({
        list: ['standard', 'garden', 'skylight', 'garden-skylight'],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 80,
          fullPrice: 18_000,
        },
        skylight: {
          price: 90,
          fullPrice: 20_000,
        },
        'garden-skylight': {
          price: 170,
          fullPrice: 38_000,
        },
      }),
      bathroom: {
        list: ['master'],
        master: { title: 'Master' },
      },
      floorPlan: [
        [
          { group: 'bathroom', name: 'standard' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'kitchen', name: '1' },
          { group: 'dinning', name: 'standard-1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { group: 'living', name: 'lr-1' },
          { group: 'living', name: 'lr-2' },
        ],
      ],
    },
    m10a: {
      title: '1-2 Bedroom',
      description:
        'Let the outdoors come in with Neu’s signature center courtyard. This 1 or 2 bedroom configuration give you the flexibility to bring the outdoors in.',
      features: ['starting at 800 sq ft'],
      price: 1095,
      fullPrice: 243_500,
      exterior: getExteriorData({
        list: ['wood', 'metal'],
        wood: {
          price: 0,
          colors: {
            list: ['white-blue'],
            'white-blue': { price: 0, fullPrice: 0 },
          },
        },
        metal: {
          price: 0,
          colors: {
            list: ['silver', 'copper'],
            copper: { price: 0, fullPrice: 0 },
            silver: { price: 0, fullPrice: 0 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 180,
          addOnFullPrice: 40_000,
        },
        concrete: {
          price: 80,
          fullPrice: 18_000,
          addOnPrice: 180,
          addOnFullPrice: 40_000,
        },
        dark: {
          price: 100,
          fullPrice: 23_300,
          addOnPrice: 180,
          addOnFullPrice: 40_000,
        },
      }),
      roof: getRoofData({
        list: [
          'standard',
          'garden',
          'skylight',
          'garden-skylight',
          'porch',
          'canopy',
          'roof-deck',
          // 'porch-garden',
          'roof-deck-garden',
        ],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 120,
          fullPrice: 28_000,
        },
        skylight: {
          price: 180,
          fullPrice: 40_000,
        },
        'garden-skylight': {
          price: 300,
          fullPrice: 68_000,
        },
        porch: {
          price: 300,
          fullPrice: 67_000,
        },
        canopy: {
          price: 500,
          fullPrice: 111_000,
        },
        'roof-deck': {
          price: 800,
          fullPrice: 178_000,
        },
        'roof-deck-garden': {
          price: 920,
          fullPrice: 206_000,
        },
        'porch-garden': {
          price: 420,
          fullPrice: 95_000,
        },
      }),
      bathroom: {
        list: ['master'],
        master: { title: 'Master' },
      },
      floorPlan: [
        [
          { group: 'living', name: 'lr-1' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'dinning', name: 'standard-1' },
          { group: 'kitchen', name: '1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { vacant: true },
          { group: 'bathroom', name: 'standard' },
        ],
      ],
    },
    m16a: {
      title: '2-3 Bedroom',
      description:
        'More space for your growing family. Start here or even start with a smaller unit and add this configuration as your family grows.',
      features: ['starting at 1,440 sq ft'],
      price: 1575,
      fullPrice: 350_500,
      exterior: getExteriorData({
        list: ['metal'],
        metal: {
          price: 0,
          colors: {
            list: ['silver', 'black', 'white-red'],
            silver: { price: 0, fullPrice: 0 },
            black: { price: 0, fullPrice: 0 },
            'white-red': { price: 0, fullPrice: 0 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['light', 'concrete', 'dark'],
        light: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 200,
          addOnFullPrice: 44_500,
        },
        concrete: {
          price: 100,
          fullPrice: 22_300,
          addOnPrice: 200,
          addOnFullPrice: 44_500,
        },
        dark: {
          price: 120,
          fullPrice: 26_800,
          addOnPrice: 200,
          addOnFullPrice: 44_500,
        },
      }),
      roof: getRoofData({
        list: [
          'standard',
          'garden',
          'skylight',
          'garden-skylight',
          'porch',
          'canopy',
          'roof-deck',
          // 'porch-garden',
          'roof-deck-garden',
        ],
        standard: {
          price: 0,
          fullPrice: 0,
        },
        garden: {
          price: 160,
          fullPrice: 35_500,
        },
        skylight: {
          price: 240,
          fullPrice: 53_500,
        },
        'garden-skylight': {
          price: 400,
          fullPrice: 89_000,
        },
        porch: {
          price: 300,
          fullPrice: 67_000,
        },
        canopy: {
          price: 500,
          fullPrice: 111_000,
        },
        'roof-deck': {
          price: 800,
          fullPrice: 178_000,
        },
        'roof-deck-garden': {
          price: 960,
          fullPrice: 213_500,
        },
        'porch-garden': {
          price: 460,
          fullPrice: 102_500,
        },
      }),
      bathroom: {
        list: ['master', 'guest'],
        master: { title: 'Master' },
        guest: {
          title: 'Guest',
        },
      },
      floorPlan: [
        [
          { group: 'living', name: 'lr-1' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'dinning', name: 'standard-1' },
          { group: 'kitchen', name: '1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { vacant: true },
          { group: 'bathroom', name: 'standard' },
        ],
      ],
    },
    cyber: {
      title: 'Neu Garage - Texas Edition',
      description:
        'Why hide in the garage when you can come inside? The Neu Garage - Texas Edition is a garage built for the 21st century with built in electric charging.',
      features: ['starting at 1,360 sq ft'],
      price: 5_395,
      fullPrice: 1_200_000,
      exterior: getExteriorData({
        list: ['metal'],
        metal: {
          price: 0,
          colors: {
            list: ['black-silver'],
            'black-silver': { price: 0, fullPrice: 0 },
          },
        },
      }),
      interior: getInteriorData({
        includes: sampleAppliances,
        addOnIncludes: sampleFurniture,
        list: ['cyber'],
        cyber: {
          price: 0,
          fullPrice: 0,
          addOnPrice: 450,
          addOnFullPrice: 100_000,
        },
      }),
      roof: getRoofData({
        list: ['canopy'],
        canopy: {
          price: 0,
          fullPrice: 0,
        },
      }),
      bathroom: {
        list: ['master', 'guest'],
        master: { title: 'Master' },
        guest: {
          title: 'Guest',
        },
      },
      floorPlan: [
        [
          { group: 'living', name: 'lr-1' },
          { group: 'bedroom', name: 'small-bed-1' },
          { group: 'dinning', name: 'standard-1' },
          { group: 'kitchen', name: '1' },
        ],
        [
          { vacant: true },
          { vacant: true },
          { vacant: true },
          { group: 'bathroom', name: 'standard' },
        ],
      ],
    },
  },
};
export const moduleDataConfig = {
  list: [
    'living',
    'bathroom',
    'bedroom',
    'closet',
    'courtyard',
    'kitchen',
    'office',
    'empty',
    'wall',
  ],
  wall: {
    title: 'Walls & Doors',
    list: ['vertical-frame', 'horizontal-frame'],
    'vertical-frame': {
      title: 'Solid Wall',
    },
    'horizontal-frame': {
      title: 'Solid Wall',
    },
  },
  living: {
    title: 'Living Rooms',
    list: ['lr-1'],
    'lr-1': {
      price: 225,
      fullPrice: 50_000,
    },
  },
  empty: {
    title: 'Empty Modules',
    list: ['em-1'],
    'em-1': { price: 115, fullPrice: 25_000 },
  },
  bedroom: {
    title: 'Bedrooms',
    list: [
      'big-bed-1',
      'big-bed-2',
      'small-bed-1',
      'small-bed-2',
      'small-bed-3',
      'small-bed-4',
      'kid-1',
      'nursery-1',
    ],
    'big-bed-1': {
      price: 335,
      fullPrice: 75_000,
    },
    'big-bed-2': {
      price: 335,
      fullPrice: 75_000,
    },
    'small-bed-1': {
      price: 225,
      fullPrice: 50_000,
    },
    'small-bed-2': {
      price: 225,
      fullPrice: 50_000,
    },
    'small-bed-3': {
      price: 225,
      fullPrice: 50_000,
    },
    'small-bed-4': {
      price: 225,
      fullPrice: 50_000,
    },
    'kid-1': {
      price: 225,
      fullPrice: 50_000,
    },
    'nursery-1': {
      price: 115,
      fullPrice: 25_000,
    },
  },
  closet: {
    title: 'Closets',
    list: ['cl-1'],
    'cl-1': {
      price: 135,
      fullPrice: 30_000,
    },
  },
  courtyard: {
    title: 'Courtyard',
    list: ['cy-1'],
    'cy-1': {
      price: 225,
      fullPrice: 50_000,
    },
  },
  dinning: {
    title: 'Dining Rooms',
    list: ['standard-1', 'standard-2'],
    'standard-1': {
      price: 5500,
    },
    'standard-2': {
      price: 5500,
    },
    // 'dr-2': {
    //   price: 9000,
    //   spaceX: 2,
    //   spaceY: 1,
    //   skew: '-9deg',
    // },
    // 'dr-3': {
    //   price: 8500,
    //   spaceX: 1,
    //   spaceY: 2,
    //   skew: '30deg',
    // },
  },
  kitchen: {
    title: 'Kitchens',
    list: ['1', '2', '3', '4', '5', '6', '7', '8', 'island-1', 'island-2'],
    1: {
      price: 150,
      fullPrice: 35_000,
    },
    2: {
      price: 150,
      fullPrice: 35_000,
    },
    3: {
      price: 150,
      fullPrice: 35_000,
    },
    4: {
      price: 150,
      fullPrice: 35_000,
    },
    5: {
      price: 150,
      fullPrice: 35_000,
    },
    6: {
      price: 150,
      fullPrice: 35_000,
    },
    7: {
      price: 150,
      fullPrice: 35_000,
    },
    8: {
      price: 150,
      fullPrice: 35_000,
    },
    'island-1': {
      price: 135,
      fullPrice: 30_000,
    },
    'island-2': {
      price: 135,
      fullPrice: 30_000,
    },
  },
  office: {
    title: 'Office',
    list: ['1', '2', '3', '4', '5', '6'],
    1: {
      price: 115,
      fullPrice: 25_000,
    },
    2: {
      price: 115,
      fullPrice: 25_000,
    },
    3: {
      price: 115,
      fullPrice: 25_000,
    },
    4: {
      price: 115,
      fullPrice: 25_000,
    },
    5: {
      price: 115,
      fullPrice: 25_000,
    },
    6: {
      price: 115,
      fullPrice: 25_000,
    },
  },
  bathroom: {
    title: 'Bathrooms',
    list: ['standard', 'premium', 'bathtub'],
    standard: {
      title: 'Standard',
      price: 180,
      fullPrice: 35_000,
      inTemplatePrice: 0, // always zero, no price, line item only
      colors: getBathroomColorData({
        list: ['gray', 'dark', 'blue', 'green'],
        gray: { price: 0, fullPrice: 0 },
        dark: { price: 0, fullPrice: 0 },
        blue: { price: 20, fullPrice: 4_500 },
        green: { price: 40, fullPrice: 9_000 },
      }),
    },
    premium: {
      title: 'Premium',
      price: 180,
      fullPrice: 35_000,
      inTemplatePrice: 0, // always zero, no price, line item only
      colors: getBathroomColorData({
        list: ['white', 'light', 'dark'],
        white: { price: 40, fullPrice: 9_000 },
        light: { price: 60, fullPrice: 13_500 },
        dark: { price: 80, fullPrice: 18_000 },
      }),
    },
    bathtub: {
      title: 'Bathtub',
      price: 225,
      fullPrice: 50_000,
      inTemplatePrice: 0, // always zero, no price, line item only
      colors: getBathroomColorData({
        list: ['white', 'light', 'dark'],
        white: { price: 100, fullPrice: 22_300 },
        light: { price: 120, fullPrice: 28_000 },
        dark: { price: 140, fullPrice: 29_000 },
      }),
    },
  },
};
export const communityLotDataConfig = {
  defaultChoice: 'lake-travis',
  list: ['mountain', 'lake-travis', 'bastrop'],
  'lake-travis': {
    title: 'Neu Lake Travis',
    subtitle: 'Travis County, TX',
    description:
      'Waterfront living. Leave your car behind while you live, work and play on Neu’s private beachfront.',
    features: ['4,000+ ft of Lake Travis Shoreline', 'Starting at $1,100/mo'],
    map: {
      placeId: 'ChIJlRQ9PEsjW4YRgms59-6F5XQ',
      latLong: '30.46256930734096,-97.92783071266162',
      center: '30.358243269979724,-97.82638130374926',
      zoom: 10,
    },
    lots: {
      list: ['forest', 'lake-view', 'lake-tree-house'],
      defaultChoice: 'forest',
      forest: {
        price: 1000,
        fullPrice: 222_500,
        title: 'Forest Lot',
        description:
          'Spectacular settings nestled in the woods. Create your own private retreat surrounded by nature.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 12 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a'],
        },
        limit: [4, 3],
      },
      'lake-view': {
        price: 1500,
        fullPrice: 333_500,
        title: 'Lake View Lot',
        description:
          'Enjoy the view of Lake Travis from your front porch. Build your Neu lake home today.',
        features: [
          'Units start at 800 sq ft',
          'Supports expansion up to 12 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a'],
        },
        limit: [4, 3],
      },
      'lake-tree-house': {
        price: 2000,
        fullPrice: 445_000,
        title: 'Lake Tree House Lot',
        unitsLeft: 4,
        description:
          'Lifted into the trees this is elevated living at its finest. Wake up every morning to a tree top view of Lake Travis.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 12 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a'],
        },
        limit: [4, 3],
      },
    },
  },
  bastrop: {
    title: 'Neu Forest',
    subtitle: 'Bastrop County, TX',
    description:
      'Spectacular setting nestled in the woods. Create your own private retreat surrounded by nature.',
    features: ['20 mins from Tesla', 'Starting at $795/mo'],
    map: {
      placeId: 'ChIJadvR6FuaRIYRYNmtW2Y8DTQ',
      latLong: '30.118624045699878,-97.38107421912822',
      center: '30.215635952355623,-97.52168164189945',
      zoom: 10,
    },
    lots: {
      list: ['forest', 'trails', 'garage'],
      defaultChoice: 'forest',
      forest: {
        price: 200,
        fullPrice: 45_000,
        title: 'Forest Lot',
        description:
          'Spectacular setting nestled in the woods. Create your own private retreat surrounded by nature.',
        features: [
          'Units start at 320 sq ft',
          'Supports expansion up to 8 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b'],
        },
        limit: [4, 2],
      },
      trails: {
        price: 300,
        fullPrice: 66_500,
        title: 'Trails Lot',
        description:
          'Steps away from the trails and the Neu mobility hub, this location gives you easy and convent access around the community.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 18 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b'],
        },
        limit: [6, 3],
      },
      garage: {
        price: 400,
        fullPrice: 89_000,
        title: 'Private Lot',
        description:
          'With your own private yard and garage this location gives you a traditional feel while being part of a Neu Community.',
        features: [
          'Units start at 800 sq ft',
          'Supports expansion up to 24 modules',
        ],
        units: {
          list: ['m10a', 'm16a', 'cyber'],
        },
        limit: [8, 3],
      },
    },
  },
  mountain: {
    title: 'Neu Mountain View',
    subtitle: 'Travis County, TX',
    description:
      'Why should millionaires be the only ones to wake up to a million dollar view. Build your home with a view today.',
    features: [
      '2,000 Acres near highest point in Travis County',
      'Starting at $895/mo',
    ],
    map: {
      placeId: 'ChIJBxbC204lW4YRwn5mm2Qk8NI',
      latLong: '30.520347989868622,-97.97937881416648',
      center: '30.392431456612986,-97.86912795999295',
      zoom: 10,
    },
    lots: {
      list: ['forest', 'trails', 'garage', 'sunset-view', 'tree-house'],
      defaultChoice: 'forest',
      forest: {
        price: 300,
        fullPrice: 66_500,
        title: 'Forest Lot',
        unitsLeft: 84,
        description:
          'Spectacular setting nestled in the woods. Create your own private retreat surrounded by nature.',
        features: [
          'Units start at 320 sq ft',
          'Supports expansion up to 8 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b'],
        },
        limit: [4, 2],
      },
      trails: {
        price: 400,
        fullPrice: 89_000,
        title: 'Trails Lot',
        description:
          'Steps away from the trails and the Neu mobility hub, this location gives you easy and convenient access around the community.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 18 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a'],
        },
        limit: [6, 3],
      },
      garage: {
        price: 600,
        fullPrice: 133_500,
        title: 'Private Lot',
        description:
          'With your own private yard and garage this location gives you a traditional feel while being part of a Neu Community.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 24 modules',
        ],
        units: {
          list: ['m10a', 'm16a', 'cyber'],
        },
        limit: [8, 3],
      },
      'sunset-view': {
        price: 3000,
        fullPrice: 667_500,
        title: 'Sunset View Lot',
        description:
          'Living life at the edge. Your home has a front row seat to every sunset.',
        features: [
          'Units start at 800 sq ft',
          'Supports expansion up to 24 modules',
        ],
        units: {
          list: ['m10a', 'm16a', 'cyber'],
        },
        limit: [8, 3],
      },
      'tree-house': {
        price: 2000,
        fullPrice: 445_000,
        title: 'Tree House Lot',
        description:
          'Lifted into the trees this is elevated living at its finest. Neu Tree Houses come with indoor living up above and outdoor living down below.',
        features: [
          'Units start at 480 sq ft',
          'Supports expansion up to 12 modules',
        ],
        units: {
          list: ['m4a', 'm4b', 'm6a', 'm6b', 'm10a'],
        },
        limit: [4, 3],
      },
    },
  },
};

export const ownRentModalContent = {
  textBlock1: {
    heading: 'Neu home ownership, made easy',
    subheading: 'First come, first serve, no offer home buying',
    paragraph:
      'Frustrated with the old way to buy a home? Tired of trying to buy a home for your family only to be outbid by other offers who can pay more than asking price and pay cash? Now there is a Neu way to become a homeowner! Watch the video below to learn more.',
  },
  textBlock2: {
    heading: 'Neu renter',
    paragraph:
      'Not everyone is ready yet to buy a home. That is why at Neu we offer our Neu renter program. Each month Neu credits a portion of your rent to your Neu down payment fund so you can easily become a Neu owner when you are ready.',
  },
  video: {
    url: 'https://firebasestorage.googleapis.com/v0/b/neu-community.appspot.com/o/own-rent-0507.mp4?alt=media&token=1d1d5501-8b5b-4bd5-b74d-0cf225c6785a',
  },
  comparison: {
    left: {
      heading: 'Neu Owner',
      items: [
        'Down payments starting at $4,995',
        'Customize your Neu home',
        'Grow your home on-demand',
        'Fully furnished options',
        'Neu smart home technology',
      ],
    },
    right: {
      heading: 'Neu Renter',
      items: [
        'Starting at $925/month',
        'Customize your Neu home',
        '1 month deposit',
        '2 year lease',
        'Neu down payment fund',
      ],
    },
  },
};
export const partnerModalContent = {
  thankYouMessage: "Thank you for the message. We'll contact you soon!",
};
export const newCityFormContent = {
  heading: "Don't see your city?",
  paragraph:
    'Neu is expanding rapidly. Let us know where you’d like Neu to bring your community.',
  thankYouMessage:
    "Thank you for submitting your city. We'll keep you updated!",
};
export const renterModalContent = {
  heading: 'Account details & payment',
  thankYouMessage:
    'Thank you for placing a reservation with us. A Neu team member will be in touch soon.',
};
export const ownerModalContent = {
  heading: 'Owner Account & Payment',
  thankYouMessage:
    "Thank you for placing a reservation with us. We've sent you an email with your reservation information and we'll reach out when your unit is ready.",
};
export const sponsorContent = {
  heading: 'Sponsor Account & Payment',
  thankYouMessage:
    'Thank you for sponsoring homes for the community. A Neu team member will be in touch soon.',
};
export const galleryPageContent = {
  tabs: [
    { name: 'general', title: 'General' },
    { name: 'mountain', title: 'Neu Mountain' },
    { name: 'lake', title: 'Neu Lake' },
    { name: 'forest', title: 'Neu Forest' },
  ],
  videos: {
    mountain:
      'https://firebasestorage.googleapis.com/v0/b/neu-community.appspot.com/o/gallery-mountain.mp4?alt=media&token=6e86d20f-a021-4859-bbce-ce53530edeab',
    lake: 'https://firebasestorage.googleapis.com/v0/b/neu-community.appspot.com/o/gallery-lake.mp4?alt=media&token=ae1c3208-ad3b-45f4-b0ce-c55270057cad',
    forest:
      'https://firebasestorage.googleapis.com/v0/b/neu-community.appspot.com/o/gallery-forest.mp4?alt=media&token=06866289-98b8-4c2e-99e3-50fc53824025',
  },
};
export const floorPlansPageContent = {
  hero: {
    heading: 'Neu homes grow with your family',
    paragraph:
      'Never remodel again. Just Plug-in-Live additional space as you need it.',
  },
  features: {
    heading: 'Neu smart home',
    paragraph:
      'Technology plays a role in almost all facets of our life. Yet traditional houses are still made like they were in the 1950’s. At Neu integrating our home experience with technology is not an afterthought.',
    list: [
      {
        heading: 'Rearrangeable',
        paragraph:
          'Why rearrange your furniture when you can rearrange your entire home',
      },
      {
        heading: 'Eco-Friendly',
        paragraph:
          'Integrated with solar and built with energy efficient materials',
      },
      {
        heading: 'Automated',
        paragraph: 'Smart home appliances help automate your home',
      },
      {
        heading: 'Updates',
        paragraph:
          'Your Neu home updates itself by downloading the latest Neu home software',
      },
      {
        heading: '10-year warranty',
        paragraph:
          'Your Neu home comes with a 10-year warranty on the structural components',
      },
      {
        heading: 'Safety',
        paragraph:
          'Each home is integrated with sprinklers and home security systems',
      },
    ],
  },
  builderDemo: {
    heading: 'BYOH - build your own home',
    subheading: 'Plug-in-Live Space on Demand',
    paragraph: `Choose any template floor plan and then add or subtract your own choice of modules to build the perfect home to fit your family.

A Neu home grows with your family through every season of your life. Start building your own Neu home today.`,
  },
  callToAction: `Neu Community
Community + Nature + Technology`,
};
export const communitiesPageContent = {
  community: communityLotDataConfig,
};
export const homePageContent = {
  banner: 'Opening in Austin, Texas Fall 2021.',
  hero: {
    heading: 'Neu Way of Living',
    subheading: 'Community + Nature + Technology',
    video:
      'https://firebasestorage.googleapis.com/v0/b/neu-community.appspot.com/o/drone.mp4?alt=media&token=e3dc8865-ea1d-42df-b12b-c746de0f4a5c',
  },
  section1: {
    heading: `Choose your
Neu Community`,
    paragraph:
      'Welcome to Neu, where the nature of community is focused on New Eco-suitable Urban living. Blending natural surroundings with the latest technology, Neu provides connections with a true sense of self.',
  },
  section2: {
    heading: `Neu model of home ownership`,
    subheading: 'Own more than a house, own a part of your community',
    paragraph: `With prices skyrocketing, buying a home has become out of reach for many Americans. It’s time for a Neu way to own where you live.

Each Neu Community comes with a Neu housing co-operative. This allows us to offer families lower down payments and lower monthly payments than traditional mortgages. With down payments starting at $4,995.

Neu’s First Come, First Serve, No Offer policy means you do not have to compete with other families bidding up the price of your home. Best of all 100% of housing in the Neu co-op is owner occupied meaning you are not competing against investors to buy a home.`,
  },
  section3: {
    heading: 'Neu Community, blending nature with technology',
    carousel: [
      {
        heading: 'Transportation on-demand',
        paragraph:
          'Why have a car payment when you can access the Neu mobility share program.',
      },
      {
        heading: 'The best front yard',
        paragraph:
          'From your front doorstep to community trails in under 60 seconds. Experience nature daily.',
      },
      {
        heading: 'Neu Main St.',
        paragraph:
          'From restaurants to shopping and entertainment there is something for everyone at every age.',
      },
      {
        heading: 'Stay healthy',
        paragraph:
          'Focus on Mind, Body, and Spirit with easy access to recreation, telemedicine clinics, and areas to energize or unwind.',
      },
      {
        heading: 'The essentials',
        paragraph:
          'Run out of milk? Just pop into the 24/7 automated micro-markets to grab your groceries and essentials.',
      },
    ],
    features: [
      {
        heading: 'The best front yard',
        paragraph:
          'From your front doorstep to community trails in under 60 seconds. Experience nature daily.',
      },
      {
        heading: 'Transportation on-demand',
        paragraph:
          'Why have a car payment when you can access the Neu mobility share program.',
      },
      {
        heading: 'Ditch the commute',
        paragraph:
          'Your Neu “Home Office.” Reserve your own micro-office steps away from home or use one of our shared office spaces spread through the community.',
      },
      {
        heading: 'The garage',
        paragraph:
          'No need for everyone to have every tool and toy. Our shared community garage allows you to check out what you need for how long you need it.',
      },
      {
        heading: 'Stay healthy',
        paragraph:
          'Focus on Mind, Body, and Spirit with easy access to recreation, telemedicine clinics, and areas to energize or unwind.',
      },
      {
        heading: 'Living to learn',
        paragraph:
          'Building minds along with communities. Neu offers education and childcare embedded right in the neighborhood.',
      },
      {
        heading: 'The essentials',
        paragraph:
          'Run out of milk? Just pop into the 24/7 automated micro-markets to grab your groceries and essentials.',
      },
      {
        heading: 'Neu Main St.',
        paragraph:
          'From restaurants to shopping and entertainment there is something for everyone at every age.',
      },
    ],
  },
  section4: {
    heading: 'Neu home, built by you',
    content: {
      heading:
        'Neu homes grow with your family, instead of your family out growing your home',
      paragraph: `As a Neu owner or renter you can customize the size, shape, look and feel of your home.

Need more space after you have moved in? Just had a baby? Plug-in-Live your Neu Nursery. Using your Neu Community App you can request additional modules at anytime. At Neu we make sure your home fits you in every season of your life.`,
    },
    comparison: [
      {
        heading: 'Traditional rental',
        paragraph: 'No sense of ownership and no chance to build equity.',
      },
      {
        heading: 'Traditional mortgage',
        paragraph: 'Very expensive and requires big commitment.',
      },
      {
        heading: 'Neu ownership',
        paragraph: 'Own the land and invest in the community you live in.',
      },
    ],
  },
  section5: {
    heading: 'Visit our Austin showroom',
    paragraph:
      'Get started by customizing your Neu home and reserving your spot in one of our Neu communities. Once we receive your reservation a Neu team member will reach out to schedule an in-person or virtual visit to our Austin showroom where you can finalize your Neu home design and complete your order.',
  },
};
export const builderAreaList = [
  // { title: 'Community', name: 'community' },
  // { title: 'Lot type', name: 'lot' },
  { title: 'Community', name: 'community-lot' },
  { title: 'Unit type', name: 'unit' },
  { title: 'Exterior', name: 'exterior', controlled: true },
  { title: 'Roof type', name: 'roof', controlled: true },
  { title: 'Interior', name: 'interior', controlled: true },
  { title: 'Bathroom', name: 'bathroom', controlled: true },
];

export const builderNavList = [
  ...builderAreaList,
  { title: 'Summary', name: 'summary', controlled: true },
];

export const summaryContent = {
  owner: {
    title: 'Neu owner',
    description:
      'Become a member of the community and own the land you live on. Not only do you get a state-of-the-art custom home, but membership gives you access to all the amenities of the Neu Community network.',
  },
  renter: {
    title: 'Renter',
    description:
      'Become a member of the community and own the land you live on. Not only do you get a state-of-the-art custom home, but membership gives you access to all the amenities of the Neu Community network.',
  },
};

// Drag and drop library
export const dragDropItemTypes = {
  MODULE: 'module',
  WALL_VERTICAL: 'wall/vertical',
  WALL_HORIZONTAL: 'wall/horizontal',
};

// pricing
export const MONTHLY_RENT_TO_PAYMENT_RATIO = 1.3;
