import {
  communityLotDataConfig,
  moduleDataConfig,
  MONTHLY_RENT_TO_PAYMENT_RATIO,
  unitDataConfig,
} from './dataConfig';
import { changeIntent, changeOption } from '../store/build';

// currency format
export const formatPrice = price => {
  return typeof window === 'undefined'
    ? formatPriceNode(price)
    : formatPriceBrowser(price);
};
export const formatPriceNode = price => `$${price}`;
export const formatPriceBrowser = price =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(price);
export const formatExtraPerMonth = price =>
  price > 0 ? `+ ${formatPrice(price)} / mon` : '$0 / mon';
export const formatPricePerMonth = price =>
  price > 0 ? `${formatPrice(price)} / mon` : 'included';
export const formatExtraPerMonthCheckbox = price =>
  price > 0 ? `(+ ${formatPrice(price)} / mon)` : '(included)';
export const filterImages = (images, imageNames) => {
  return imageNames.map(name => images.find(image => image.name === name));
};

// form
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateForm = data => {
  const { email, phone, firstName, lastName, comments } = data;
  let errors = {};
  firstName !== undefined && (errors = validateFirstName(firstName, errors));
  lastName !== undefined && (errors = validateLastName(lastName, errors));
  email !== undefined && (errors = validateEmail(email, errors));
  phone !== undefined && (errors = validatePhone(phone, errors));
  // comments !== undefined && (errors = validateComments(comments, errors));
  const valuesAreValid = Object.keys(errors).length === 0;
  return { valuesAreValid, errors };
};

function validateFirstName(name, prevErrors) {
  if (name.trim() === '') {
    return prevErrors;
  }
  if (name.trim().length < 2 || name.trim().length > 1000) {
    return { ...prevErrors, firstName: 'Please enter a valid name' };
  }
  return prevErrors;
}

function validateLastName(name, prevErrors) {
  if (name.trim() === '') {
    return { ...prevErrors, lastName: 'Last name is required' };
  }
  if (name.trim().length < 2 || name.trim().length > 1000) {
    return { ...prevErrors, lastName: 'Please enter a valid name' };
  }
  return prevErrors;
}

function validateComments(text, prevErrors) {
  if (text.trim() === '') {
    return { ...prevErrors, comments: 'Please enter comments' };
  }
  if (text.trim().length < 5) {
    return { ...prevErrors, comments: 'Please be more specific' };
  }
  return prevErrors;
}

function validateEmail(email, prevErrors) {
  if (email.trim() === '') {
    return { ...prevErrors, email: 'Email is required' };
  }
  if (!emailRegex.test(email)) {
    return { ...prevErrors, email: 'Invalid email address' };
  }
  return prevErrors;
}

function validatePhone(phone, prevErrors) {
  if (phone.trim() !== '' && phone.trim().length < 10) {
    return { ...prevErrors, phone: 'Please enter a valid number' };
  }
  return prevErrors;
}

export const injectExtraFormData = (formName, formValues, dependencies) => {
  const {
    buildState,
    totalMonthly,
    totalDownPayment,
    totalFullPrice,
  } = dependencies;
  const data = { ...formValues };
  if (
    formName === 'renter' ||
    formName === 'owner' ||
    formName === 'saveBuild'
  ) {
    data.build = buildState;
    data.quoteMonthly = totalMonthly;
    data.quoteDownPayment = totalDownPayment;
    data.fullPrice = totalFullPrice;
  }
  if (typeof window !== 'undefined') {
    const source = localStorage.getItem('utm_source');
    const campaign = localStorage.getItem('utm_campaign');
    const medium = localStorage.getItem('utm_medium');
    source && (data.utm_source = source);
    campaign && (data.utm_campaign = campaign);
    medium && (data.utm_medium = medium);
  }
  return data;
};

// drag and drop
export function updateItemOnFloorPlanArray(array, location, data) {
  const newArray = JSON.parse(JSON.stringify(array));
  const { x, y } = location;
  newArray[y][x] = { ...data, vacant: false };
  // multi-module
  const itemSpecs = moduleDataConfig[data.group][data.name];
  if (itemSpecs.spaceX) {
    // inject space specs
    newArray[y][x].spaceX = itemSpecs.spaceX;
    newArray[y][x].spaceY = itemSpecs.spaceY;
    // mark needed spaces in array
    let xCount = itemSpecs.spaceX - 1;
    let yCount = itemSpecs.spaceY - 1;
    while (xCount > 0) {
      newArray[y][x + xCount] = { origin: [x, y], vacant: false };
      --xCount;
    }
    while (yCount > 0) {
      newArray[y + yCount][x] = { origin: [x, y], vacant: false };
      --yCount;
    }
  }

  return newArray;
}

export function removeItemFromFloorPlanArray(array, location) {
  const newArray = JSON.parse(JSON.stringify(array));
  const { x, y } = location;
  const item = newArray[y][x];
  if (item.spaceX) {
    // multi module item
    let xCount = item.spaceX - 1;
    let yCount = item.spaceY - 1;
    while (xCount > 0) {
      newArray[y][x + xCount] = { vacant: true };
      --xCount;
    }
    while (yCount > 0) {
      newArray[y + yCount][x] = { vacant: true };
      --yCount;
    }
  }
  newArray[y][x] = { vacant: true };
  return newArray;
}

export function canPlaceHere(x, y, item, floorPlanArray) {
  // inject space specs
  const newItem = { ...item };
  const itemSpecs = moduleDataConfig[item.group][item.name];
  newItem.spaceX = itemSpecs.spaceX;
  newItem.spaceY = itemSpecs.spaceY;

  if (typeof newItem.x === 'undefined') {
    // no previous location, adding item to floor plan
    return canAddHere(x, y, newItem, floorPlanArray);
  }
  return canMoveHere({ x: newItem.x, y: newItem.y }, { x, y }, floorPlanArray);
}

export function canAddHere(x, y, item, floorPlanArray) {
  if (item.spaceX) {
    // multi module
    let canAdd = true;
    let xCount = item.spaceX - 1;
    let yCount = item.spaceY - 1;
    while (xCount > 0) {
      if (
        !floorPlanArray[y][x + xCount] ||
        !floorPlanArray[y][x + xCount].vacant
      ) {
        canAdd = false;
        break;
      }
      --xCount;
    }
    while (yCount > 0) {
      if (
        !floorPlanArray[y + yCount] ||
        !floorPlanArray[y + yCount][x].vacant
      ) {
        canAdd = false;
        break;
      }
      --yCount;
    }

    return canAdd;
  } else {
    // single module
    return floorPlanArray[y][x].vacant;
  }
}

export function canMoveHere(prevLocation, newLocation, floorPlanArray) {
  const item = floorPlanArray[prevLocation.y][prevLocation.x];
  const itemRemovedFloorPlan = removeItemFromFloorPlanArray(floorPlanArray, {
    x: prevLocation.x,
    y: prevLocation.y,
  });
  return canAddHere(newLocation.x, newLocation.y, item, itemRemovedFloorPlan);
}

export function createFloorPlan(template, limit) {
  const floorPlanWidth = limit[0];
  const templateWidth = template[0].length;
  const xSpacerStart = Math.floor((floorPlanWidth - templateWidth) / 2);
  const xSpacerEnd = floorPlanWidth - templateWidth - xSpacerStart;

  const floorPlanHeight = limit[1];
  const templateHeight = template.length;
  const ySpacerStart = Math.floor((floorPlanHeight - templateHeight) / 2);
  const ySpacerEnd = floorPlanHeight - templateHeight - ySpacerStart;

  const floorPlan = [];

  // add starting vacant rows
  const singleVacantRow = Array(floorPlanWidth).fill({ vacant: true });
  if (ySpacerStart > 0) {
    Array(ySpacerStart)
      .fill(true) // placeholder for running 'forEach'
      .forEach(() => floorPlan.push(singleVacantRow));
  }
  // add template, each row at a time
  template.forEach(row => {
    const start = Array(xSpacerStart).fill({ vacant: true });
    const end = Array(xSpacerEnd).fill({ vacant: true });
    const markedRow = row.map(item =>
      item.name ? { ...item, fromTemplate: true, vacant: false } : item
    );
    const completeRow = [...start, ...markedRow, ...end];
    floorPlan.push(completeRow);
  });
  // add ending vacant rows
  if (ySpacerEnd > 0) {
    Array(ySpacerEnd)
      .fill(true) // placeholder for running 'forEach'
      .forEach(() => floorPlan.push(singleVacantRow));
  }
  return floorPlan;
}

export function updateWallOnFloorPlan(floorPlanArray, location, side, wall) {
  const newArray = JSON.parse(JSON.stringify(floorPlanArray));
  const { x, y } = location;
  // update current module
  const prevItem = newArray[y][x];
  newArray[y][x].walls = { ...prevItem.walls, [side]: wall.name };
  // update adjacent module if there is one
  return updateAdjacentModuleWall(newArray, location, side, wall);
}

export function updateAdjacentModuleWall(
  floorPlanArray,
  currentLocation,
  side,
  wall
) {
  const { x, y } = currentLocation;
  const prevItem = floorPlanArray[y][x];
  const {
    x: adjacentX,
    y: adjacentY,
    side: adjacentSide,
  } = getAdjacentModuleWall({ x, y }, side, {
    spaceX: prevItem.spaceX,
    spaceY: prevItem.spaceY,
  });
  if (
    floorPlanArray[adjacentY] &&
    floorPlanArray[adjacentY][adjacentX] &&
    !floorPlanArray[adjacentY][adjacentX].vacant
  ) {
    // if passed in null, remove wall instead
    wall
      ? (floorPlanArray[adjacentY][adjacentX].walls = {
          ...floorPlanArray[adjacentY][adjacentX].walls,
          [adjacentSide]: wall.name,
        })
      : floorPlanArray[adjacentY][adjacentX].walls &&
        floorPlanArray[adjacentY][adjacentX].walls[adjacentSide] &&
        delete floorPlanArray[adjacentY][adjacentX].walls[adjacentSide];
  }

  return floorPlanArray;
}

export function resetWallsOnFloorPlan(floorPlanArray, location) {
  const newArray = JSON.parse(JSON.stringify(floorPlanArray));
  const { x, y } = location;
  const prevItem = newArray[y][x];
  // remove shared walls on all adjacent modules
  Object.keys(prevItem.walls).forEach(side => {
    updateAdjacentModuleWall(newArray, location, side, null);
  });
  // remove walls on current module
  const { walls: no_op, ...newItem } = prevItem;
  newArray[y][x] = newItem;
  return newArray;
}

export function getAdjacentModuleWall(position, side, space) {
  const { x, y } = position;
  const { spaceX, spaceY } = space;
  switch (side) {
    case 'left': {
      return { x: x - 1, y, side: 'right' };
    }
    case 'right': {
      return { x: x + 1 + (spaceX ? spaceX : 0), y, side: 'left' };
    }
    case 'top': {
      return { x, y: y - 1, side: 'bottom' };
    }
    case 'bottom': {
      return { x, y: y + 1 + (spaceY ? spaceY : 0), side: 'top' };
    }
  }
}

export function getAdjustedPriceFromIntent(price, intent) {
  return intent === 'own' ? price : price * MONTHLY_RENT_TO_PAYMENT_RATIO;
}

export function presetBuilder(dispatch, config) {
  const { community, lot, unit, intent } = config;
  if (community) {
    communityLotDataConfig[community]
      ? dispatch(changeOption('community', community))
      : dispatch(changeOption('community', communityLotDataConfig.list[0]));
  }
  if (lot) {
    communityLotDataConfig[community].lots[lot]
      ? dispatch(changeOption('lot', lot))
      : dispatch(
          changeOption(
            'lot',
            communityLotDataConfig[communityLotDataConfig.list[0]].lots.list[0]
          )
        );
  }
  if (unit) {
    unitDataConfig.options[unit]
      ? dispatch(changeOption('unit', unit))
      : dispatch(changeOption('unit', unitDataConfig.options.list[0]));
  }
  if (intent) {
    dispatch(changeIntent(intent));
  }
}

// misc
export const mapUnitToExteriorObjectPosition = {
  m4a: '41% 50%',
  m4b: '26% 50%',
  m6a: '41% 50%',
  m6b: '41% 50%',
  m10a: '70% 50%',
  m16a: '70% 50%',
};
export const getTrimColorByColorName = color => color.split('-')[0];
export const storeReferralData = params => {
  if (typeof window !== 'undefined') {
    const source = params.get('utm_source');
    const campaign = params.get('utm_campaign');
    const medium = params.get('utm_medium');
    source && localStorage.setItem('utm_source', source);
    campaign && localStorage.setItem('utm_campaign', campaign);
    medium && localStorage.setItem('utm_medium', medium);
  }
};
export const getCountdown = endDate => {
  // https://www.w3schools.com/howto/howto_js_countdown.asp
  const now = Date.now();
  const distance = endDate - now;
  const countdown = {
    days: Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, "0"),
    hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0"),
    minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0"),
    seconds: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, "0")
  }
  return countdown;
};
export const getPadding = (x, y) => {
  const maxX = 8,
    maxY = 3;
  const xPaddingUnitsEachSide = (maxX - x) / 2;
  const xPadding = xPaddingUnitsEachSide / maxX;
  const yPaddingUnitsEachSide = (maxY - y) / 2;
  const yPadding = yPaddingUnitsEachSide / maxY;
  return `${yPadding * 100}% ${xPadding * 100}%`;
};
