import React from 'react';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { buildReducer } from './build';
import { appReducer } from './app';
import { gtmEvents } from './middleware';

const reducer = combineReducers({ build: buildReducer, app: appReducer });

export const wrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const enhancerWithGtmEvents = composeEnhancers(applyMiddleware(gtmEvents));
  const enhancer =
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
  const store =
    typeof window !== 'undefined' && process.env.NODE_ENV === 'development'
      ? createStore(reducer, enhancer)
      : createStore(reducer, applyMiddleware(gtmEvents));
  return <Provider store={store}>{element}</Provider>;
};
