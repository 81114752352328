export const gtmEvents = () => next => action => {
  typeof document !== `undefined` &&
    (window.dataLayer = window.dataLayer || []);

  const at = action.type.split('/');
  const event = at.shift() + '-action';
  const actionType = at.pop();

  const data = {
    event,
    actionType
  }

  if (action.payload) {
    if (typeof action.payload === 'string') {
      if (['areaChanged', 'addOnToggled'].includes(actionType)) {
        data.area = action.payload;
      } else if (['intentChanged', 'intentSwitched'].includes(actionType)) {
        data.intent = action.payload;
      } else {
        data.payload = action.payload;
      }
    } else {
      let area, intent, payload;

      if (action.payload.area) {
        ({ area, ...payload } = action.payload);
        data.area = area;
      } else if (action.payload.intent) {
        ({ intent, ...payload } = action.payload);
        data.intent = intent;
      } else {
        payload = action.payload;
      }

      const keys = Object.keys(payload);
      if (keys.length) {
        if (keys.length === 1) {
          data.payload = payload[keys[0]];
        } else {
          data.payload = JSON.stringify(payload);
        }
      } else {
        data.payload = '';
      }
    }
  }

  window.dataLayer.push(data);
  return next(action);
};
