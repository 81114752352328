import {
  communityLotDataConfig,
  moduleDataConfig,
  unitDataConfig,
} from '../utils/dataConfig';
import {
  createFloorPlan,
  getAdjustedPriceFromIntent,
  removeItemFromFloorPlanArray,
  resetWallsOnFloorPlan,
  updateAdjacentModuleWall,
  updateItemOnFloorPlanArray,
  updateWallOnFloorPlan,
} from '../utils/helpers';

const initialState = {
  ui: {
    area: 'community-lot',
    limit: 'invalid-unit', // 'empty-floor-plan', 'invalid-unit'
    nav: true,
    currentItemGroup: null,
    currentBathroom: null,
    loader: {},
  },
  intent: 'own',
  community: communityLotDataConfig.list[0],
  lot: communityLotDataConfig[communityLotDataConfig.list[0]].lots.list[0],
  unit: null,
  floorPlan: null,
  exterior: {
    material: null,
    color: null,
  },
  roof: null,
  interior: {
    color: null,
    addOn: false,
  },
  bathroom: {
    list: [],
    extraModuleList: [],
  },
};

export const buildReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BUILDER_RESET:
      return initialState;
    case BUILDER_AREA_CHANGED:
      return {
        ...state,
        ui: { ...state.ui, area: payload },
      };
    case BUILDER_AREA_LIMIT_CHANGED:
      return {
        ...state,
        ui: { ...state.ui, limit: payload },
      };
    case BUILDER_NAV_TOGGLED:
      return {
        ...state,
        ui: { ...state.ui, nav: payload },
      };
    case ITEM_GROUP_CHANGED: {
      return {
        ...state,
        ui: { ...state.ui, currentItemGroup: payload },
      };
    }
    case CURRENT_BATHROOM_CHANGED: {
      return {
        ...state,
        ui: { ...state.ui, currentBathroom: payload },
      };
    }
    case ASSET_LOADED:
      return {
        ...state,
        ui: { ...state.ui, loader: { ...state.ui.loader, [payload]: true } },
      };
    case INTENT_SWITCHED: {
      return { ...state, intent: state.intent === 'own' ? 'rent' : 'own' };
    }
    case INTENT_CHANGED: {
      return { ...state, intent: payload };
    }
    case OPTION_CHANGED: {
      const { area, option } = payload;
      if (area === 'interior') {
        return {
          ...state,
          [area]: {
            ...state[area],
            color: option,
          },
        };
      }
      if (area === 'exterior') {
        // if previous color is valid in new material, leave it unchanged
        // if (
        //   unitDataConfig.options[state.unit].exterior[option].colors[
        //     state.exterior.color
        //   ]
        // ) {
        //   return {
        //     ...state,
        //     exterior: {
        //       ...state.exterior,
        //       material: option,
        //     },
        //   };
        // }
        // color not applicable, reset color selection to default
        return {
          ...state,
          exterior: {
            material: option,
            color:
              unitDataConfig.options[state.unit].exterior[option].colors
                .list[0],
          },
        };
      }
      if (area === 'community') {
        const newCommunity = option;
        let newLot = state.lot;
        const selectedUnit = state.unit;
        // if previous lot is not available in new community, change lot to default option
        if (!communityLotDataConfig[newCommunity].lots[newLot]) {
          newLot = communityLotDataConfig[newCommunity].lots.list[0];
        }
        // skip verifying entire state if no unit was selected
        if (!selectedUnit) {
          return { ...state, community: newCommunity, lot: newLot };
        }
        // check if selected unit is still valid and set navigation limit
        const newLotUnits =
          communityLotDataConfig[newCommunity].lots[newLot].units.list;
        let newNavLimit = false;
        !newLotUnits.includes(selectedUnit) && (newNavLimit = 'invalid-unit');

        // if invalid, set unit to NULL, else verify entire state in case lot size limit changes
        return newNavLimit
          ? {
              ...state,
              community: newCommunity,
              lot: newLot,
              unit: null,
              ui: { ...state.ui, limit: newNavLimit },
            }
          : verifyAndUpdateBuildByUnitType(
              {
                ...state,
                community: newCommunity,
                lot: newLot,
                ui: { ...state.ui, limit: false },
              },
              selectedUnit
            );
      }
      if (area === 'lot') {
        const newLot = option;
        const selectedCommunity = state.community;
        const selectedUnit = state.unit;
        // skip verifying entire state if no unit was selected
        if (!selectedUnit) {
          return { ...state, lot: newLot };
        }
        // check if selected unit is still valid and set navigation limit
        const newLotUnits =
          communityLotDataConfig[selectedCommunity].lots[newLot].units.list;
        let newNavLimit = false;
        !newLotUnits.includes(selectedUnit) && (newNavLimit = 'invalid-unit');

        // if invalid, set unit to NULL, else verify entire state in case lot size limit changes
        return newNavLimit
          ? {
              ...state,
              lot: newLot,
              unit: null,
              ui: { ...state.ui, limit: newNavLimit },
            }
          : verifyAndUpdateBuildByUnitType(
              { ...state, lot: newLot, ui: { ...state.ui, limit: false } },
              selectedUnit
            );
      }
      if (area === 'unit') {
        // set navigation limit if invalid in current lot, else verify entire state
        let newNavLimit = false;
        !communityLotDataConfig[state.community].lots[
          state.lot
        ].units.list.includes(option) && (newNavLimit = 'invalid-unit');

        return newNavLimit
          ? {
              ...state,
              unit: option,
              ui: { ...state.ui, limit: newNavLimit },
            }
          : verifyAndUpdateBuildByUnitType(
              { ...state, ui: { ...state.ui, limit: false } },
              option
            );
      }

      return {
        ...state,
        [area]: option,
      };
    }
    case BATHROOM_OPTION_CHANGED: {
      const { bathroom, option } = payload;
      // if color is available in new option, leave it unchanged
      if (
        moduleDataConfig.bathroom[option].colors.list.includes(
          state.bathroom[bathroom].color
        )
      ) {
        return {
          ...state,
          bathroom: {
            ...state.bathroom,
            [bathroom]: {
              ...state.bathroom[bathroom],
              option,
            },
          },
        };
      }
      // color is not applicable, set default color
      return {
        ...state,
        bathroom: {
          ...state.bathroom,
          [bathroom]: {
            color: moduleDataConfig.bathroom[option].colors.list[0],
            option,
          },
        },
      };
    }

    case ADDON_TOGGLED: {
      const area = payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          addOn: !state[area].addOn,
        },
      };
    }
    case COLOR_CHANGED: {
      const { area, color } = payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          color,
        },
      };
    }
    case BATHROOM_COLOR_CHANGED: {
      const { bathroom, color } = payload;
      return {
        ...state,
        bathroom: {
          ...state.bathroom,
          [bathroom]: {
            ...state.bathroom[bathroom],
            color,
          },
        },
      };
    }
    case BUILD_RECEIVED:
      return {
        ...state,
        ui: { ...state.ui, area: 'summary', limit: false },
        ...payload,
      };
    case FLOOR_PLAN_ITEM_ADDED: {
      const { x, y, item } = payload;
      let newBathroomState = state.bathroom;
      let newItem = { ...item };

      if (item.group === 'bathroom') {
        // inject module name and add it to bathroom state for customizing later
        const moduleNamePrefix =
          item.name === 'bathtub' ? 'extra-bathtub-' : 'extra-';
        // find an available number
        let moduleNumber = 0;
        let available = false;
        while (!available) {
          ++moduleNumber;
          available = true;
          state.bathroom.extraModuleList.forEach(name => {
            +name.split('-').slice(-1) === moduleNumber && (available = false);
          });
        }
        const moduleName = moduleNamePrefix + moduleNumber;
        newItem.moduleName = moduleName;
        newBathroomState = {
          ...state.bathroom,
          extraModuleList: [...state.bathroom.extraModuleList, moduleName],
          [moduleName]: {
            option: item.name,
            color: moduleDataConfig.bathroom[item.name].colors.list[0],
          },
        };
      }

      const newFloorPlan = updateItemOnFloorPlanArray(
        state.floorPlan,
        { x, y },
        newItem
      );
      let newLimit =
        state.ui.limit === 'empty-floor-plan' ? false : state.ui.limit;
      return {
        ...state,
        ui: { ...state.ui, limit: newLimit },
        bathroom: newBathroomState,
        floorPlan: newFloorPlan,
      };
    }
    case FLOOR_PLAN_ITEM_MOVED: {
      const { prevLocation, newLocation } = payload;
      const item = state.floorPlan[prevLocation.y][prevLocation.x];
      // Remove previously all adjacent walls
      let adjacentWallsRemoved = state.floorPlan;
      item.walls &&
        Object.keys(item.walls).forEach(side => {
          adjacentWallsRemoved = updateAdjacentModuleWall(
            adjacentWallsRemoved,
            prevLocation,
            side,
            null
          );
        });
      // Remove current item walls
      const { walls: no_op, ...newItem } = item;
      // Remove current item
      const itemRemoved = removeItemFromFloorPlanArray(adjacentWallsRemoved, {
        x: prevLocation.x,
        y: prevLocation.y,
      });
      let newFloorPlan = updateItemOnFloorPlanArray(
        itemRemoved,
        newLocation,
        newItem
      );
      // Remove adjacent walls in new location
      ['left', 'right', 'top', 'bottom'].forEach(side => {
        newFloorPlan = updateAdjacentModuleWall(
          newFloorPlan,
          newLocation,
          side,
          null
        );
      });

      return {
        ...state,
        floorPlan: newFloorPlan,
      };
    }
    case FLOOR_PLAN_ITEM_REMOVED: {
      const { x, y } = payload;
      const item = state.floorPlan[y][x];
      let bathroomState = state.bathroom;
      let currentBathroom = state.ui.currentBathroom;
      if (item.group === 'bathroom') {
        // remove module from bathroom state
        const { [item.moduleName]: removed, ...newBathroomState } =
          bathroomState;
        newBathroomState.extraModuleList =
          newBathroomState.extraModuleList.filter(
            name => name !== item.moduleName
          );
        bathroomState = newBathroomState;
        // reset current bathroom
        currentBathroom = null;
      }
      const newFloorPlan = removeItemFromFloorPlanArray(state.floorPlan, {
        x,
        y,
      });
      let newLimit = state.ui.limit;
      // set limit if floor plan is empty
      let allVacant = true;
      const totalRows = newFloorPlan.length;
      const totalCols = newFloorPlan[0].length;
      let currentRow = 1,
        currentCol = 1;
      while (allVacant) {
        if (currentRow > totalRows) {
          break;
        }
        !newFloorPlan[currentRow - 1][currentCol - 1].vacant &&
          (allVacant = false);
        if (currentCol === totalCols) {
          currentRow++;
          currentCol = 1;
        } else {
          currentCol++;
        }
      }
      allVacant && (newLimit = 'empty-floor-plan');

      return {
        ...state,
        ui: { ...state.ui, limit: newLimit, currentBathroom },
        bathroom: bathroomState,
        floorPlan: newFloorPlan,
      };
    }
    case FLOOR_PLAN_CHANGED: {
      return { ...state, floorPlan: payload };
    }
    case FLOOR_PLAN_RESET: {
      const limit =
        communityLotDataConfig[state.community].lots[state.lot].limit;
      const template = unitDataConfig.options[state.unit].floorPlan;
      const newFloorPlan = createFloorPlan(template, limit);
      return { ...state, floorPlan: newFloorPlan };
    }
    case WALL_ADDED: {
      const { x, y, side, wall } = payload;
      const newFloorPlan = updateWallOnFloorPlan(
        state.floorPlan,
        { x, y },
        side,
        wall
      );
      return { ...state, floorPlan: newFloorPlan };
    }
    case WALL_RESET: {
      const newFloorPlan = resetWallsOnFloorPlan(state.floorPlan, payload);
      return { ...state, floorPlan: newFloorPlan };
    }
    default:
      return state;
  }
};

// selectors
export const getCurrentArea = state => state.build.ui.area;
export const getAreaLimit = state => state.build.ui.limit;
export const getNavOn = state => state.build.ui.nav;
export const getCurrentBathroom = state =>
  state.build.ui.currentBathroom || state.build.bathroom.list[0];
export const getCurrentItemGroup = state =>
  state.build.ui.currentItemGroup || moduleDataConfig.list[0];
export const getLoadedAssets = state => state.build.ui.loader;
export const getSelectedIntent = state => state.build.intent;
export const getSelectedCommunity = state => state.build.community;
export const getSelectedRoof = state => state.build.roof;
export const getSelectedUnit = state => state.build.unit;
export const getSelectedLot = state => state.build.lot;
export const getSelectedInterior = state => state.build.interior.color;
export const getSelectedInteriorAddOn = state => state.build.interior.addOn;
export const getSelectedExteriorMaterial = state =>
  state.build.exterior.material;
export const getSelectedExteriorColor = state => state.build.exterior.color;
export const getBathroomConfig = state => state.build.bathroom;
export const getFloorPlan = state => state.build.floorPlan;
export const getBuildState = state => state.build;
export const listAllSelectedOptions = build => {
  /*
  purposes:
  1) show summary to user, later in email - a list of option titles and prices
  2) save to database - field name (area + sub-selections), option titles, option names, prices, total prices
  copied to two other services: payment rest, database rest
  */

  const intent = build.intent;

  // compose bathroom items
  const bathroomItems = [];
  const allBathroomList = [
    ...build.bathroom.list,
    ...build.bathroom.extraModuleList,
  ];
  allBathroomList.forEach(bathroom => {
    // upgrade options
    bathroomItems.push({
      // item: `bathroom-${bathroom}-option`,
      item: `bathroom-option`,
      bathroomName: bathroom,
      option: build.bathroom[bathroom].option,
      title:
        (unitDataConfig.options[build.unit].bathroom[bathroom]?.title ||
          'Extra') +
        ' bathroom: ' +
        moduleDataConfig.bathroom[build.bathroom[bathroom].option].title,
      price: 0,
      fullPrice: 0,
    });
    // colors
    bathroomItems.push({
      // item: `bathroom-${bathroom}-color`,
      item: `bathroom-color`,
      option: build.bathroom[bathroom].color,
      title:
        moduleDataConfig.bathroom[build.bathroom[bathroom].option].colors[
          build.bathroom[bathroom].color
        ].title,
      price: getAdjustedPriceFromIntent(
        moduleDataConfig.bathroom[build.bathroom[bathroom].option].colors[
          build.bathroom[bathroom].color
        ].price,
        intent
      ),
      fullPrice:
        moduleDataConfig.bathroom[build.bathroom[bathroom].option].colors[
          build.bathroom[bathroom].color
        ].fullPrice,
    });
  });

  // compose floor plan items
  const floorPlanItems = [];
  build.floorPlan.forEach(row => {
    row.forEach(({ name, group, fromTemplate, moduleName }) => {
      // if it's a user added module
      if (name && !fromTemplate) {
        // replace bathroom module option with data from state
        const option =
          group === 'bathroom' ? build.bathroom[moduleName].option : name;
        floorPlanItems.push({
          item: `module-${group}`,
          title: `${moduleDataConfig[group].title} (${name}) module`,
          option,
          price: getAdjustedPriceFromIntent(
            moduleDataConfig[group][name].price,
            intent
          ),
          fullPrice: moduleDataConfig[group][name].fullPrice,
        });
      }
    });
  });

  return [
    {
      item: 'community',
      option: build.community,
      title: communityLotDataConfig[build.community].title,
      price: 0,
      fullPrice: 0,
    },
    {
      item: 'lot',
      option: build.lot,
      title: communityLotDataConfig[build.community].lots[build.lot].title,
      price: getAdjustedPriceFromIntent(
        communityLotDataConfig[build.community].lots[build.lot].price,
        intent
      ),
      fullPrice:
        communityLotDataConfig[build.community].lots[build.lot].fullPrice,
    },
    {
      item: 'unit',
      option: build.unit,
      title: unitDataConfig.options[build.unit].title,
      price: getAdjustedPriceFromIntent(
        unitDataConfig.options[build.unit].price,
        intent
      ),
      fullPrice: unitDataConfig.options[build.unit].fullPrice,
    },
    ...floorPlanItems,
    {
      item: 'exterior-material',
      option: build.exterior.material,
      title:
        unitDataConfig.options[build.unit].exterior[build.exterior.material]
          .title,
      price: 0,
      fullPrice: 0,
    },
    {
      item: 'exterior-color',
      option: build.exterior.color,
      title:
        unitDataConfig.options[build.unit].exterior[build.exterior.material]
          .colors[build.exterior.color].title,
      price: getAdjustedPriceFromIntent(
        unitDataConfig.options[build.unit].exterior[build.exterior.material]
          .colors[build.exterior.color].price,
        intent
      ),
      fullPrice:
        unitDataConfig.options[build.unit].exterior[build.exterior.material]
          .colors[build.exterior.color].fullPrice,
    },
    {
      item: 'roof',
      option: build.roof,
      title:
        unitDataConfig.options[build.unit].roof[build.roof].title + ' roof',
      price: getAdjustedPriceFromIntent(
        unitDataConfig.options[build.unit].roof[build.roof].price,
        intent
      ),
      fullPrice: unitDataConfig.options[build.unit].roof[build.roof].fullPrice,
    },
    {
      item: 'interior',
      option: build.interior.color,
      title:
        unitDataConfig.options[build.unit].interior[build.interior.color]
          .title + ` interior`,
      price: getAdjustedPriceFromIntent(
        unitDataConfig.options[build.unit].interior[build.interior.color].price,
        intent
      ),
      fullPrice:
        unitDataConfig.options[build.unit].interior[build.interior.color]
          .fullPrice,
    },
    {
      item: 'interior-addOn',
      option: build.interior.addOn,
      title: '+ Include furnishings',
      price: getAdjustedPriceFromIntent(
        unitDataConfig.options[build.unit].interior[build.interior.color].addOn
          .price,
        intent
      ),
      fullPrice:
        unitDataConfig.options[build.unit].interior[build.interior.color].addOn
          .fullPrice,
    },
    ...bathroomItems,
  ];
};
export const getSummaryData = state => {
  const summaryData = [];
  let unitWithLotPrice = 0;
  const exteriorLineItemTitle = ['Exterior'];
  let bathroomLineItemTitle = [];
  let thisBathroomFree = false;
  listAllSelectedOptions(state.build).forEach(
    ({ item, option, title, price, fullPrice, bathroomName }) => {
      switch (item) {
        case 'lot':
          summaryData.push({ title, price: 0, fullPrice });
          unitWithLotPrice += price;
          break;
        case 'unit':
          unitWithLotPrice += price;
          summaryData.push({ title, price: unitWithLotPrice, fullPrice });
          break;
        case 'exterior-material':
          exteriorLineItemTitle.push(title);
          break;
        case 'exterior-color':
          exteriorLineItemTitle.push(title);
          summaryData.push({
            title: exteriorLineItemTitle.join(' '),
            price,
            fullPrice,
          });
          break;
        case 'bathroom-option':
          option && (bathroomLineItemTitle = [title]);
          option &&
            bathroomName.startsWith('extra-bathtub') &&
            (thisBathroomFree = true);
          break;
        case 'bathroom-color':
          bathroomLineItemTitle.push(title);
          const newPrice = thisBathroomFree ? 0 : price;
          thisBathroomFree = false;
          summaryData.push({
            title: bathroomLineItemTitle.join(' '),
            price: newPrice,
            fullPrice,
          });
          break;
        default:
          option && summaryData.push({ title, price, fullPrice });
      }
    }
  );
  return summaryData;
};
export const getTotalPrice = state => {
  if (state.build.unit && !state.build.ui.limit) {
    return getSummaryData(state).reduce((acc, { price }) => acc + price, 0);
  } else {
    return 0;
  }
};
export const getTotalFullPrice = state => {
  if (state.build.unit && !state.build.ui.limit) {
    return getSummaryData(state).reduce(
      (acc, { fullPrice }) => acc + fullPrice,
      0
    );
  } else {
    return 0;
  }
};
export const getTotalDownPayment = state => {
  const mapUnitToPercentDown = {
    m4a: 0.03,
    m4b: 0.03,
    m6a: 0.03,
    m6b: 0.03,
    m10a: 0.05,
    m16a: 0.05,
    cyber: 0.05,
  };
  const { intent, unit } = state.build;

  if (intent === 'own') {
    const totalFullPrice = getTotalFullPrice(state);
    return totalFullPrice * mapUnitToPercentDown[unit];
  } else {
    // renter: 1 month rent as down payment
    return getTotalPrice(state);
  }
};
export const getTotalDueToday = state =>
  state.build.intent === 'own' ? 100 : 0;
// action types
export const BUILDER_AREA_CHANGED = 'builder/ui/areaChanged';
export const BUILDER_AREA_LIMIT_CHANGED = 'builder/ui/areaLimitChanged';
export const ITEM_GROUP_CHANGED = 'builder/ui/itemGroupChanged';
export const CURRENT_BATHROOM_CHANGED = 'builder/ui/currentBathroomChanged';
export const ASSET_LOADED = 'builder/ui/assetLoaded';
export const OPTION_CHANGED = 'builder/optionChanged';
export const BATHROOM_OPTION_CHANGED = 'builder/bathroomOptionChanged';
export const ADDON_TOGGLED = 'builder/addOnToggled';
export const COLOR_CHANGED = 'builder/colorChanged';
export const BATHROOM_COLOR_CHANGED = 'builder/bathroomColorChanged';
export const BUILD_RECEIVED = 'builder/buildReceived';
export const FLOOR_PLAN_ITEM_ADDED = 'builder/floorPlanItemAdded';
export const FLOOR_PLAN_ITEM_MOVED = 'builder/floorPlanItemMoved';
export const FLOOR_PLAN_ITEM_REMOVED = 'builder/floorPlanItemRemoved';
export const FLOOR_PLAN_CHANGED = 'builder/floorPlanChanged';
export const FLOOR_PLAN_RESET = 'builder/floorPlanReset';
export const WALL_ADDED = 'builder/wallAdded';
export const WALL_RESET = 'builder/wallReset';
export const INTENT_SWITCHED = 'builder/intentSwitched';
export const INTENT_CHANGED = 'builder/intentChanged';
export const BUILDER_RESET = 'builder/reset';
export const BUILDER_NAV_TOGGLED = 'builder/navToggled';

// action creators
export const changeArea = area => ({
  type: BUILDER_AREA_CHANGED,
  payload: area,
});
export const changeAreaLimit = limited => ({
  type: BUILDER_AREA_LIMIT_CHANGED,
  payload: limited,
});
export const toggleNav = isOn => ({
  type: BUILDER_NAV_TOGGLED,
  payload: isOn,
});
export const changeItemGroup = group => ({
  type: ITEM_GROUP_CHANGED,
  payload: group,
});
export const changeCurrentBathroom = bathroom => {
  return { type: CURRENT_BATHROOM_CHANGED, payload: bathroom };
};
export const setLoadedAsset = name => ({ type: ASSET_LOADED, payload: name });
export const switchIntent = () => ({
  type: INTENT_SWITCHED,
});
export const changeIntent = intent => ({
  type: INTENT_CHANGED,
  payload: intent,
});
export const changeOption = (area, option) => {
  return {
    type: OPTION_CHANGED,
    payload: { area, option },
  };
};
export const changeBathroomOption = (bathroom, option) => {
  return {
    type: BATHROOM_OPTION_CHANGED,
    payload: { bathroom, option },
  };
};
export const changeColor = (area, color) => {
  return {
    type: COLOR_CHANGED,
    payload: { area, color },
  };
};
export const changeBathroomColor = (bathroom, color) => {
  return {
    type: BATHROOM_COLOR_CHANGED,
    payload: { bathroom, color },
  };
};
export const toggleAddOn = area => {
  return {
    type: ADDON_TOGGLED,
    payload: area,
  };
};
export const updateBuild = build => {
  const { date, id, ...strippedBuild } = build;
  return { type: BUILD_RECEIVED, payload: strippedBuild };
};

export const addItemToFloorPlan = (x, y, item) => {
  return { type: FLOOR_PLAN_ITEM_ADDED, payload: { x, y, item } };
};
export const moveItemOnFloorPlan = (prevLocation, newLocation) => {
  return {
    type: FLOOR_PLAN_ITEM_MOVED,
    payload: { prevLocation, newLocation },
  };
};
export const removeItemFromFloorPlan = (x, y) => ({
  type: FLOOR_PLAN_ITEM_REMOVED,
  payload: { x, y },
});
export const setFloorPlan = newFloorPlanArray => ({
  type: FLOOR_PLAN_CHANGED,
  payload: newFloorPlanArray,
});
export const updateWall = (x, y, side, wall) => ({
  type: WALL_ADDED,
  payload: { x, y, side, wall },
});
export const resetWalls = (x, y) => ({ type: WALL_RESET, payload: { x, y } });

export const resetFloorPlan = () => ({ type: FLOOR_PLAN_RESET });

export const resetBuilder = () => ({ type: BUILDER_RESET });

function verifyAndUpdateBuildByUnitType(state, unit) {
  const newState = { unit, intent: state.intent };
  const unitConfig = unitDataConfig.options[unit];

  // exterior
  const availableExteriorMaterial = unitConfig.exterior.list;
  const selectedExteriorMaterial = availableExteriorMaterial.includes(
    state.exterior.material
  )
    ? state.exterior.material
    : availableExteriorMaterial[0];
  const availableExteriorColor =
    unitConfig.exterior[selectedExteriorMaterial]?.colors?.list || [];
  const selectedExteriorColor = state.exterior.color;

  const material = availableExteriorMaterial.includes(selectedExteriorMaterial)
    ? selectedExteriorMaterial
    : availableExteriorMaterial[0];
  const color = availableExteriorColor.includes(selectedExteriorColor)
    ? selectedExteriorColor
    : availableExteriorColor[0];
  newState.exterior = {
    material,
    color,
  };

  // roof
  const availableRoof = unitConfig.roof.list;
  const selectedRoof = state.roof;
  newState.roof = availableRoof.includes(selectedRoof)
    ? selectedRoof
    : availableRoof[0];

  // interior
  const availableInterior = unitConfig.interior.list;
  const selectedInterior = state.interior.color;
  const selectedAddOn = state.interior.addOn;
  newState.interior = {
    color: availableInterior.includes(selectedInterior)
      ? selectedInterior
      : availableInterior[0],
    addOn: selectedAddOn,
  };

  // bathroom
  const unitIncludedBathrooms = unitConfig.bathroom.list;

  const bathroomConfigState = { ...state.bathroom };
  unitIncludedBathrooms.forEach(bathroom => {
    // verify and update current config state
    if (!bathroomConfigState[bathroom]) {
      const bathroomModule = moduleDataConfig.bathroom;
      bathroomConfigState[bathroom] = {
        option: bathroomModule.list[0],
        color: bathroomModule[bathroomModule.list[0]].colors.list[0],
      };
    }
  });
  newState.bathroom = {
    ...bathroomConfigState,
    list: unitIncludedBathrooms,
  };
  newState.ui = { ...state.ui, currentBathroom: null };

  // floor plan
  const limit = communityLotDataConfig[state.community].lots[state.lot].limit;
  const template = unitDataConfig.options[unit].floorPlan;
  newState.floorPlan = createFloorPlan(template, limit);

  return { ...state, ...newState };
}
